<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex>
        <!-- <v-card> -->
        <v-card-title class="elevation-0">
          <span class="itemHeading">Add Asset</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-layout wrap justify-start>
          <v-flex xs12 pa-4>
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex xs12 pa-2 pb-10>
            <v-card outlined tile>
              <v-layout wrap pa-3>
                <v-flex xs12 align-self-center text-left pa-2>
                  <span class="kumbhSemibold"
                    >Donor Information (Optional)</span
                  >
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left pa-2>
                  <v-select
                    v-model="fundType"
                    :items="fundList"
                    item-text="title"
                    item-value="val"
                    hide-details
                    outlined
                    dense
                    label="Choose Fund type"
                    @change="donorCountry=null"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left pa-2 v-if="!flag && fundType">
                  <v-select
                    v-model="donorId"
                    :items="donorList"
                    item-text="name"
                    item-value="_id"
                    hide-details
                    outlined
                    dense
                    label="Choose Donor"
                  >
                  </v-select>
                </v-flex>

                <v-flex xs12 sm6 align-self-center text-left pa-2 v-if="fundType">
                  <v-btn
                    color="#005f32"
                    small
                    dark
                    @click="flag = true,donorId=null"
                    class="text-none rounded-xl"
                  >
                    <span>Add New Donor</span>
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left pa-2 v-if="flag">
                  <v-text-field
                    outlined
                    dense
                    v-model="donorName"
                    label="Name"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left pa-2 v-if="flag">
                  <v-text-field
                    outlined
                    dense
                    v-model="donorEmail"
                    label="Email"
                    :rules="emailRules"
                    hide-details
                  ></v-text-field>
                </v-flex>
                <v-flex
                  xs12
                  sm6
                  align-self-center
                  text-left
                  pa-2
                  v-if="flag && fundType == 'Foreign Fund'"
                >
                  <v-text-field
                    outlined
                    dense
                    v-model="donorCountry"
                    label="Country"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 align-self-center text-left pa-3>
            <span style="font-size: 18px" class="kumbhMedium">
              Select Asset type
            </span>
          </v-flex>
          <v-flex>
            <v-radio-group v-model="radios" mandatory column>
              <v-radio
                color="#005f32"
                label="Already Exists"
                class="kumbhEBold"
                value="1"
              ></v-radio>
              <v-radio
                color="#005f32"
                label="New Asset"
                class="kumbhEBold"
                value="2"
              ></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 pa-4>
            <v-spacer></v-spacer>
          </v-flex>
          <v-flex xs12 align-self-center fill-height pa-0 v-if="radios == 1">
            <v-card tile flat>
              <v-form v-model="addcat" ref="addcat">
                <v-layout wrap>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetId"
                      :items="assetList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose asset"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="unitPrice"
                      label="Unit Price (per piece)"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>

                  <!-- <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assignType"
                      :items="assignList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose assign type"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Department'"
                  >
                    <v-select
                      v-model="departmentId"
                      :items="deptList"
                      item-text="deptName"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose department"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Employee'"
                  >
                    <v-select
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose employee"
                    >
                    </v-select>
                  </v-flex> -->

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="quantity"
                      label="Quantity"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>

                  <v-flex xs12 sm6 align-self-center pa-2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm6 align-self-center px-4>
                        <v-btn
                          color="#005f32"
                          dark
                          tile
                          large
                          class="rounded-xl"
                          block
                          @click="validateInput()"
                        >
                          Submit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>

          <v-flex xs12 align-self-center fill-height pa-0 v-if="radios == 2">
            <v-card tile flat>
              <v-form v-model="addcat2" ref="addcat2">
                <v-layout wrap>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="name"
                      label="Asset Name"
                      :rules="[rules.required]"
                      required
                      autofocus
                      hide-details
                      outlined
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetCategoryId"
                      :items="categoryList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose asset category"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assetType"
                      :items="typeList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose asset type"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="unitPrice"
                      label="Unit Price (per piece)"
                      :rules="[rules.required]"
                      required
                      hide-details
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 align-self-center text-left pa-2>
                    <v-textarea
                      v-model="description"
                      label="Description"
                      hide-details
                      outlined
                      type="number"
                      dense
                    ></v-textarea>
                  </v-flex>

                  <!-- <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-select
                      v-model="assignType"
                      :items="assignList"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose assign type"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Department'"
                  >
                    <v-select
                      v-model="departmentId"
                      :items="deptList"
                      item-text="deptName"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose department"
                    >
                    </v-select>
                  </v-flex>

                  <v-flex
                    xs12
                    sm6
                    align-self-center
                    text-left
                    pa-2
                    v-if="assignType == 'Employee'"
                  >
                    <v-select
                      v-model="employeeId"
                      :items="empList"
                      item-text="name"
                      item-value="_id"
                      :rules="[rules.required]"
                      hide-details
                      outlined
                      dense
                      label="Choose employee"
                    >
                    </v-select>
                  </v-flex> -->

                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="quantity"
                      label="Quantity"
                      :rules="[rules.required]"
                      required
                      outlined
                      type="number"
                      @wheel="$event.target.blur()"
                      dense
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center text-left pa-2>
                    <v-text-field
                      v-model="code"
                      label="Code"
                      :rules="[rules.required]"
                      required
                      outlined
                      dense
                      persistent-hint
                      hint="NB: This code could not be editable after saving."
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 align-self-center pa-2>
                    <v-layout wrap justify-center>
                      <v-flex xs12 sm6 align-self-center px-4>
                        <v-btn
                          color="#005f32"
                          dark
                          tile
                          large
                          class="rounded-xl"
                          block
                          @click="validateInput()"
                        >
                          Submit
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-form>
            </v-card>
          </v-flex>
        </v-layout>

        <!-- </v-card> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      msg: null,
      pages: 0,
      dialog: false,
      pageCount: 1,
      appLoading: false,
      editedIndex: -1,
      currentPage: 1,

      addcat: false,
      addcat2: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      radios: null,
      categoryList: [],
      assetCategoryId: null,
      name: "",
      assetType: null,
      typeList: ["Consumable", "Nonconsumable", "Semiconsumable"],
      assignList: ["Department", "Employee"],
      description: null,
      unitPrice: null,
      assignType: null,
      employeeId: null,
      departmentId: null,
      empList: [],
      deptList: [],
      assetList: [],
      quantity: null,
      assetId: null,
      code: null,
      fundType: null,
      fundList: [
        {
          title: "Foreign Fund",
          val: "Foreign Fund",
        },
        {
          title: "Indian Fund",
          val: "Indian Fund",
        },
      ],
      donorList: [],
      donorName: null,
      donorCountry:null,
      donorEmail:null,
      donorId: null,
      flag: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    };
  },
  watch: {
    radios() {
      console.log("Rad=", this.radios);
      this.clearOff();
    },
    fundType() {
      this.getDonor();
    },
  },
  beforeMount() {
    this.getCategories();
    this.getDept();
    this.getEmp();
    this.getAssets();
  },

  methods: {
    getAssets() {
      this.appLoading = true;
      axios({
        url: "/assets/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.assetList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategories() {
      this.appLoading = true;
      axios({
        url: "/assetCategory/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.categoryList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDonor() {
      this.appLoading = true;
      axios({
        url: "/donor/list",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          donorType: this.fundType
        }
      })
        .then((response) => {
          this.appLoading = false;
          this.donorList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getDept() {
      this.appLoading = true;
      axios({
        url: "/department/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.deptList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getEmp() {
      this.appLoading = true;
      axios({
        url: "/employees/getalllist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.empList = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    validateInput() {
      if(this.flag) {
        if(!this.donorName) {
          this.msg="Please provide donor name"
          this.showsnackbar=true
          return
        } else if(!this.donorCountry && this.fundType == 'Foreign Fund') {
          this.msg="Please provide donor country"
          this.showsnackbar=true
          return
        }
      }
      if (this.radios == 2) {
        if (!this.name) {
          this.msg = "Please provide asset name";
          this.showsnackbar = true;
          return;
        } else if (!this.assetCategoryId) {
          this.msg = "Please provide asset category Id";
          this.showsnackbar = true;
          return;
        } else if (!this.assetType) {
          this.msg = "Please provide asset type";
          this.showsnackbar = true;
          return;
        } else if (!this.unitPrice) {
          this.msg = "Please provide unit price";
          this.showsnackbar = true;
          return;
        } else if (!this.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else if (!this.code) {
          this.msg = "Please provide code";
          this.showsnackbar = true;
          return;
        } else {
          this.addAsset();
        }

        // else if (!this.assignType) {
        //   this.msg = "Please provide assign type";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Department" && !this.departmentId) {
        //   this.msg = "Please choose a department";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Employee" && !this.employeeId) {
        //   this.msg = "Please choose an employee";
        //   this.showsnackbar = true;
        //   return;
        // }
      } else if (this.radios == 1) {
        if (!this.assetId) {
          this.msg = "Please choose an asset";
          this.showsnackbar = true;
          return;
        } else if (!this.unitPrice) {
          this.msg = "Please provide unit price";
          this.showsnackbar = true;
          return;
        } else if (!this.quantity) {
          this.msg = "Please provide quantity";
          this.showsnackbar = true;
          return;
        } else {
          this.addAsset();
        }

        // else if (!this.assignType) {
        //   this.msg = "Please provide assign type";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Department" && !this.departmentId) {
        //   this.msg = "Please choose a department";
        //   this.showsnackbar = true;
        //   return;
        // } else if (this.assignType == "Employee" && !this.employeeId) {
        //   this.msg = "Please choose an employee";
        //   this.showsnackbar = true;
        //   return;
        // }
      }
    },
    addAsset() {
      // var data = {};
      // data["name"] = this.name;
      this.appLoading = true;
      axios({
        url: "/asset/add",
        method: "POST",
        data: {
          assetId: this.assetId,
          assetCategoryId: this.assetCategoryId,
          name: this.name,
          assetType: this.assetType,
          description: this.description,
          unitPrice: this.unitPrice,
          quantity: this.quantity,
          code: this.code,
          fundType:this.fundType,
          donorId: this.donorId,
          donorName: this.donorName,
          donorCountry: this.donorCountry,
          donorEmail: this.donorEmail,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.name = null;
            this.dialog = false;

            this.$router.push("/Assets/Inventory");
            this.clearOff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    clearOff() {
      if (this.radios == 1) {
        this.name = "";
        this.assetType = null;
        this.description = null;
        this.assetCategoryId = null;
      }
      if (this.radios == 2) {
        this.assetId = null;
        this.code = null;
      }
      this.fundType=null
      this.donorName=null
      this.donorEmail=null
      this.donorCountry=null
      this.donorId=null
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/assetCategory/remove",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: data,
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
